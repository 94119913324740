import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/img/Coins.svg'


const _hoisted_1 = { class: "calc__output" }
const _hoisted_2 = { class: "output" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Muntjes icoon"
      }, null, -1)),
      _createElementVNode("div", null, [
        _createElementVNode("h2", null, "€" + _toDisplayString((_ctx.data.salary)?.toFixed(2)?.toString().replace('.', ',')), 1),
        _cache[0] || (_cache[0] = _createElementVNode("p", null, "per week*", -1))
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.disclaimers, (disclaimer, index) => {
      return (_openBlock(), _createElementBlock("p", {
        key: index,
        innerHTML: disclaimer
      }, null, 8, _hoisted_3))
    }), 128))
  ]))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/img/Info.svg'
import _imports_1 from '../assets/img/Cover.webp'


const _hoisted_1 = { class: "page-wrapper" }
const _hoisted_2 = { class: "calc-wrapper" }
const _hoisted_3 = { class: "calc__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SalaryForm = _resolveComponent("SalaryForm")!
  const _component_SalaryOutput = _resolveComponent("SalaryOutput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "calc__form-header" }, "Hoe goed wil jij verdienen?", -1)),
      _cache[7] || (_cache[7] = _createElementVNode("p", { class: "calc__form-text" }, "Bereken zelf hoeveel je in een week met je bijbaan bij Lidl kunt verdienen. Dit hangt af van je leeftijd en het aantal uren dat je werkt. Bereken het hier!", -1)),
      _createVNode(_component_SalaryForm, {
        formData: _ctx.formData,
        onSubmitted: _ctx.submitted
      }, null, 8, ["formData", "onSubmitted"]),
      (_ctx.ageData.salary)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "calc__output-header" }, "Jouw salaris", -1)),
            _createVNode(_component_SalaryOutput, {
              data: _ctx.ageData,
              onSubmitForm: _cache[0] || (_cache[0] = (data) => _ctx.formData = data)
            }, null, 8, ["data"])
          ], 64))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("img", {
          src: _imports_0,
          alt: "Info icoon"
        }, null, -1)),
        _createElementVNode("p", null, [
          _cache[3] || (_cache[3] = _createTextVNode("Vakanties of andere perioden waarin je even meer tijd hebt om te werken? Je kunt dan tot 40 uur per week aan de slag. Bereken ")),
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData = {age: null, hours: 40}))
          }, "hier"),
          _cache[4] || (_cache[4] = _createTextVNode(" wat je maximaal in een vakantie week kunt verdienen."))
        ])
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("p", { class: "calc__disclaimer" }, "* Aan deze berekening kunnen geen rechten worden ontleend. Jouw exacte loon staat straks op jouw contract.", -1)),
      _cache[9] || (_cache[9] = _createElementVNode("p", { class: "calc__disclaimer" }, [
        _createTextVNode("* Dit is jouw all-in bruto loon."),
        _createElementVNode("br"),
        _createTextVNode("Met een bijbaan bij Lidl krijg je maandelijks alles in een keer uitbetaald. Inclusief verlof uren, ADV uren en een vakantietoeslag.")
      ], -1))
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "img-wrapper" }, [
      _createElementVNode("img", {
        class: "desktop-img",
        src: _imports_1,
        alt: "Werknemers Lidl"
      })
    ], -1))
  ]))
}
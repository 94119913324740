import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  class: "form__button",
  type: "submit",
  value: "Bereken salaris",
  ref: "submit_btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "calc__form",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('submitted', {data: _ctx.getAgeData(_ctx.age, _ctx.job, _ctx.hours), age: _ctx.age, hours: _ctx.hours})), ["prevent"]))
  }, [
    _createElementVNode("div", null, [
      _cache[3] || (_cache[3] = _createElementVNode("label", {
        class: "form__label",
        for: "age"
      }, "Hoe oud ben je?", -1)),
      _withDirectives(_createElementVNode("select", {
        class: "form__select",
        name: "age",
        id: "age",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.age) = $event))
      }, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, (a) => {
          return _createElementVNode("option", {
            value: a + 14,
            key: a
          }, _toDisplayString(a + 14) + _toDisplayString(a == 7 ? '+' : '') + " jaar", 9, _hoisted_1)
        }), 64)),
        (_ctx.formData?.age)
          ? (_openBlock(), _createElementBlock("option", {
              key: 0,
              value: _ctx.formData.age,
              hidden: ""
            }, _toDisplayString(_ctx.formData.age), 9, _hoisted_2))
          : _createCommentVNode("", true)
      ], 512), [
        [_vModelSelect, _ctx.age]
      ])
    ]),
    _createElementVNode("div", null, [
      _cache[4] || (_cache[4] = _createElementVNode("label", {
        class: "form__label",
        for: "hours"
      }, "Hoeveel uur per week wil je werken?", -1)),
      _withDirectives(_createElementVNode("select", {
        class: "form__select",
        name: "hours",
        id: "hours",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hours) = $event))
      }, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(39, (h) => {
          return _createElementVNode("option", {
            value: h + 1,
            key: h
          }, _toDisplayString(h + 1) + " uur", 9, _hoisted_3)
        }), 64)),
        (_ctx.formData?.hours)
          ? (_openBlock(), _createElementBlock("option", {
              key: 0,
              value: _ctx.formData.hours,
              hidden: ""
            }, _toDisplayString(_ctx.formData.hours) + " uur", 9, _hoisted_4))
          : _createCommentVNode("", true)
      ], 512), [
        [_vModelSelect, _ctx.hours]
      ])
    ]),
    _createElementVNode("input", _hoisted_5, null, 512)
  ], 32))
}
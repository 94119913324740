import { defineComponent } from "vue";
import { ageData, salaryData }  from '../types';

export default defineComponent({
    data () {
        return {
            salaryData: [
                {
                    job_title: "Hulpkracht",
                    salaries: [
                        {age: 15, salary: 5.90},
                        {age: 16, salary: 6.81},
                        {age: 17, salary: 7.78},
                        {age: 18, salary: 9.18},
                        {age: 19, salary: 11.02},
                        {age: 20, salary: 14.68},
                        {age: 21, salary: 18.37},
                        
                    ],
                    // Interactivity with form can be achieved using ID's but was added and later not used because of different feedback (see example below)
                    disclaimers: [

                        // {text: "Je kunt tot en met 17 jaar standaard 2 tot 12 uur per week bij ons aan de slag, afhankelijk van je beschikbaarheid en schoolrooster. Je kunt extra bijverdienen in de vakanties.", ages: [16, 17], hours: 13},

                        {text: "In een bijbaan kun je standaard tot 12 uur per week werken. Ben je 16 jaar of ouder en wil je meer dan 12 uur werken? Bekijk dan onze <a target='_parent' href='https://www.werkenbijlidl.nl/supermarkt/verkoopmedewerker'>vacatures</a> van verkoopmedewerker.", ages: [16, 17, 18, 19, 20, 21], hours: 0},

                        {text: "Ben je 15 jaar? Dan kun je in enkele geselecteerde filialen aan de slag. Bekijk de mogelijkheden <a target='_parent' href='https://www.werkenbijlidl.nl/jobsearch?page=1&query=15%20jaar&filter=%7b%22contract_type%22:%5b%223601%22%5d,%22employment_area%22:%5b%5d,%22entry_level%22:%5b%5d%7d&with_event=true'>hier</a>", ages: [15], hours: 0},

                        // Example interactivity with form
                        // {text: "Je kunt tot en met 16 jaar standaard 3 tot 12 uur per week bij ons aan de slag, afhankelijk van je beschikbaarheid en schoolrooster. Je kunt extra bijverdienen in de vakanties. Bereken <a id='holiday_btn'>hier</a> wat je maximaal in een vakantie week kunt verdienen.", ages: [15, 16, 17], hours: 12},

                        // {text: "Ben je 18 jaar of ouder en wil je wekelijks meer dan 12 uur werken? Bekijk dan onze <a href='https://www.werkenbijlidl.nl/jobsearch?page=1&filter={%22contract_type%22:[],%22employment_area%22:[],%22entry_level%22:[%226%22]}&with_event=true'>vacatures</a> van verkoopmedewerker.", ages: [15, 16, 17], hours: 12},
                    ]
                }
            ] as Array<salaryData>,
        }
    },
    methods: {
        getJobTitles() :Array<string>{
            const job_titles: Array<string> = []; 

            for(const item of this.salaryData){
                job_titles.push(item.job_title);
            }
            return job_titles;
        },

        getSalary(age: number, job: string) :number | null{

            for(const item of this.salaryData){  
                if (item.job_title == job){
                    
                    for(const salary of item.salaries){
                        if(salary.age == age){
                            return salary.salary;
                        }
                    }
                }
            }
            return null;
        },

        getDisclaimers(age: number, job: string, hours: number) :Array<string> | null{
            const disclaimers: Array<string> = [];

            for(const item of this.salaryData){  
                if (item.job_title == job){
                    
                    for(const disclaimer of item.disclaimers){
                        if(disclaimer.ages.includes(age) && disclaimer.hours <= hours){
                            disclaimers.push(disclaimer.text);
                        }
                    }
                }
            }

            if(disclaimers.length > 0){
                return disclaimers
            }
            return null
        },

        getAgeData(age: number, job: string, hours: number) :ageData{
            const salary = this.getSalary(age, job);

            const data: ageData = {
                salary: salary !== null ? Math.round((salary * hours) * 100) / 100 : null,
                disclaimers: this.getDisclaimers(age, job, hours)
            };
            return data;
        }
    }

});

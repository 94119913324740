<template>
    <article class="calc__output">
        <div class="output">
            <img src="../assets/img/Coins.svg" alt="Muntjes icoon">
            <div>
                <h2>€{{ (data.salary)?.toFixed(2)?.toString().replace('.', ',') }}</h2>
                <p>per week*</p>
            </div>
        </div>

        <p v-for="(disclaimer, index) in data.disclaimers" :key="index" v-html="disclaimer"></p>

    </article>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';
import { ageData, formData }  from '../types';

export default defineComponent({
    name: 'SalaryOutput',
    props:{
        data: {
            required: true,
            type: Object as () => ageData
        }
    },
    methods: {
        submitForm(age: number | null, hours: number | null){
            const data: formData = {
                age: age,
                hours: hours
            }

            // Event passed on to SalaryForm.vue
            this.$emit('submitForm', data)
        }
    },
    updated() {
        // Adding event listeners for possible functions in disclaimers (Found in salaryMixin.ts)
        this.$nextTick(() => {
            const holiday_btn = window.document.getElementById('holiday_btn');
            if(holiday_btn){
                /* eslint-disable */
                // @ts-ignore
                holiday_btn.addEventListener('click', () => {this.submitForm(null, 20)})
            }   
        })  
    }
});
</script>

<style lang="less" scoped>
    .calc__output{
        background: #FFFFFF;
        color: #000000;
        padding: 16px;
        border-radius: 2px;
        margin-bottom: 24px;

        >p:nth-of-type(odd){
            margin-bottom: 16px;
        }

        >p:only-of-type{
            margin-bottom: 0;
        }
    }

    .output{
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 2px solid #E7EEF2;

        display: flex;
        justify-content: space-between;
        align-items: center;

        >div{
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            h2{
                font-size: 32px;
                font-weight: 600;
            }
        }

        >img{
            padding: 11px;
            background: #A0D2FF;
            border-radius: 50%;
            border: 1px;
            overflow: visible;
        }
    }

    .output:only-child{
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
    }

    :deep(a){
        text-decoration: none;
        font-weight: 600;
        color: #0050AA;
        border-bottom: 1px solid #0050AA;
    }

    :deep(a:hover){
        cursor: pointer;
    }
</style>